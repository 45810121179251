import api from '@/api'
export default {
  namespaced: true,
  state: {
    activeDate: null,
    currentStep: null,
    stepStatus: {
      aggregator: false,
      unmatched: false,
      producers: false,
      finalize: false,
    },
  },
  getters: {
    getStepStatus: state => {
      return state.stepStatus
    },
    getActiveDate: state => {
      return state.activeDate
    },
    getCurrentStep: state => {
      return state.currentStep
    },
    getFinalized: state => {
      return state.stepStatus.finalize
    },
  },
  mutations: {
    setStepsStatuses(state, stepsStatuses) {
      state.stepStatus = stepsStatuses.attributes.step_status
      state.currentStep = stepsStatuses.attributes.current_step
    },

    setStepStatus(state, { step, stepStatus }) {
      state.stepStatus[step] = stepStatus
    },

    setActiveDate(state, date) {
      state.activeDate = date
    },
    setFurthestStep(state, step) {
      state.furthestStep = step
    },
  },
  actions: {
    async loadStepsStatuses({ commit, state }, { date }) {
      return new Promise((resolve, reject) => {
        api.commissionMonthlyReview
          .show({ date: date })
          .then(({ data }) => {
            commit('setStepsStatuses', data)
            commit('setActiveDate', date)
            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    async refreshStepsStatuses({ commit, state }) {
      return new Promise((resolve, reject) => {
        api.commissionMonthlyReview
          .show({ date: state.activeDate })
          .then(({ data }) => {
            commit('setStepsStatuses', data)
            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
