export default {
  formatRawValue(value) {
    if (value === null || value === 0) {
      return parseFloat(0).toFixed(2);
    }

    return parseFloat(value).toFixed(2)
  },
  formatCurrency(value) {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })

    return formatter.format(value)
  },
}
