import preset from '@/@core/preset/preset'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  preset,
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#29AD8E',
        secondary: '#538ECB',
        success: '#5FB322',
        info: '#538ECB',
        warning: '#F3953F',
        danger: '#F65559',
      }
    },
    options: {
      customProperties: true,
      variations: false,
    },
  },
})
