export default {
  state:{
    policyTypes: [],
    policyCreateForm: []
  },
  getters:{
    getPolicyTypes(state) {
      return state.show
    },
    getPolicyCreateForm(state) {
      return state.policyCreateForm
    },
  },
  mutations:{},
  actions:{},
}
