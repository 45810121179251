const routes = [
  {
    path: 'statement/:year/:month',
    component: () => import('@/pages/commissions/commission-statements/wizard/Index.vue'),
    children: [
      {
        path: '/',
        name: 'commission-statement.entry',
        component: () => import('@/pages/commissions/commission-statements/wizard/steps/Entry.vue'),
        meta: {
          layout: 'content',
          requireCommissionSetup: true,
          legacyRoute: '/commissions/statement/:year/:month/entry',
        },
      },
      {
        path: 'aggregator',
        name: 'commission-statement.aggregator',
        component: () => import('@/pages/commissions/commission-statements/wizard/steps/Aggregator.vue'),
        meta: {
          layout: 'content',
          requireCommissionSetup: true,
          legacyRoute: '/commissions/statement/:year/:month/aggregator',
        },
      },
      {
        path: 'unmatched',
        name: 'commission-statement.unmatched',
        component: () => import('@/pages/commissions/commission-statements/wizard/steps/Unmatched.vue'),
        meta: {
          layout: 'content',
          requireCommissionSetup: true,
          legacyRoute: '/commissions/statement/:year/:month/unmatched',
        },
      },
      {
        path: 'finalize',
        name: 'commission-statement.finalize',
        component: () => import('@/pages/commissions/commission-statements/wizard/steps/Finalize.vue'),
        meta: {
          layout: 'content',
          requireCommissionSetup: true,
          legacyRoute: '/commissions/statement/:year/:month/finalize',
        },
      },
      {
        path: 'producers',
        name: 'commission-statement.producers',
        component: () => import('@/pages/commissions/commission-statements/wizard/steps/Producers.vue'),
        meta: {
          layout: 'content',
          requireCommissionSetup: true,
          legacyRoute: '/commissions/statement/:year/:month/producers',
        },
      },
      {
        path: ':producer',
        name: 'commission-statement.producer',
        component: () => import('@/pages/commissions/commission-statements/wizard/steps/Producer.vue'),
        meta: {
          layout: 'content',
          requireCommissionSetup: true,
          legacyRoute: '/commissions/statement/:year/:month/:producer',
        },
      },
    ],
  },
]

export default routes
