<template>
  <layout-blank style="padding:5px;" id="embeddedLayout">
    <slot></slot>
    <div id="bottom-identifier"></div>
  </layout-blank>
</template>

<script>
import LayoutBlank from '@/@core/layouts/variants/blank/LayoutBlank.vue'

export default {
  components: {
    LayoutBlank,
  },
}
</script>

<style>
#embeddedLayout {
  background: none !important;
}
</style>
