import globals from '@/config/globals'
import axios from 'axios'

export default {
  show({ date }) {
    return axios.get(`${globals.getApiHost()}/commissions-statements/${date}/pay-period`)
  },
  summary({ date }) {
    return axios.get(`${globals.getApiHost()}/commissions-statements/${date}/pay-period/summary`)
  },
  completeStep({ date, step }) {
    return axios.put(`${globals.getApiHost()}/commissions-statements/${date}/pay-period/complete-step`, {
      step: step,
    })
  },
  finalize({ date }) {
    return axios.put(`${globals.getApiHost()}/commissions-statements/${date}/finalize`)
  },
  export({ date }) {
    return axios.get(`${globals.getApiHost()}/commissions-statements/${date}/pay-period/export`)
  },
  exportDetailed({ date }) {
    return axios.get(`${globals.getApiHost()}/commissions-statements/${date}/pay-period/export-detailed`)
  },
}
