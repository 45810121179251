const routes = [
  {
    path: '/rater-configuration',
    name: 'rater-configuration',
    component: () => import('@/pages/rater/configuration/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/rater',
    name: 'rater-applications',
    component: () => import('@/pages/rater/applications/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
];

export default routes;
