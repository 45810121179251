// import axios from 'axios';
// import globals from '../config/globals'

import axios from "axios";
import globals from "@/config/globals";

export default {
  index(filters = {}) {
    return axios.get(`${globals.getApiHost()}/contacts`, {
      params: {
        filters: filters
      }
    });
  },

  create(data) {
    return axios.post(`${globals.getApiHost()}/contacts`, data);
  },

  createAuto(contact, data) {
    return axios.post(`${globals.getApiHost()}/contacts/${contact}/assets`, {type: 'auto', data: data});
  },

  createProperty(contact, data) {
    return axios.post(`${globals.getApiHost()}/contacts/${contact}/assets`, {type: 'property', data: data});
  },

  getAssets(contact) {
    return axios.get(`${globals.getApiHost()}/contacts/${contact}/assets`);
  },

  getUser(contact) {
    return axios.get(`${globals.getApiHost()}/contacts/${contact}`);
  },

  getPolicies(contact) {
    return axios.get(`${globals.getApiHost()}/contacts/${contact}/policies`);
  },

  update(data) {
    console.log(data.attrs);
    return axios.put(`${globals.getApiHost()}/contacts/${data.id}`, data.attrs);
  }
}
