import commissionsOverviewRoutes from './commissions/commissions-overview'
import commissionsSettingsRoutes from './commissions/commissions-settings'
import commissionsStatementsRoutes from './commissions/commissions-statements'

const routes = [
  {
    path: '/commissions',
    component: () => import('@/pages/commissions/Index.vue'),
    children: [
      {
        name: 'commissions',
        path: '/',
        component: () => import('@/pages/commissions/commission-landing/Index.vue'),
        meta: {
          layout: 'content',
        },
      },
      ...commissionsOverviewRoutes,
      ...commissionsStatementsRoutes,
      ...commissionsSettingsRoutes,
    ],
  },
]

export default routes
