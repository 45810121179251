const routes = [
  {
    path: 'overview/:year/:month',
    component: () => import('@/pages/commissions/commission-overview/Index.vue'),
    name: 'commission-overview',
    redirect: { name: 'commission-overview.entry' },
    children: [
      {
        path: '/',
        name: 'commission-overview.entry',
        component: () => import('@/pages/commissions/commission-overview/pages/Entry.vue'),
        meta: {
          layout: 'content',
          requireCommissionSetup: true,
          legacyRoute: '/commissions/overview/:year/:month/entry',
        },
      },
      {
        path: 'summary',
        name: 'commission-overview.summary',
        component: () => import('@/pages/commissions/commission-overview/pages/Summary.vue'),
        meta: {
          layout: 'content',
          requireCommissionSetup: true,
          legacyRoute: '/commissions/overview/:year/:month/summary',
        },
      },
      {
        path: ':producer',
        name: 'commission-overview.producer',
        component: () => import('@/pages/commissions/commission-overview/pages/Producer.vue'),
        meta: {
          layout: 'content',
          requireCommissionSetup: true,
          legacyRoute: '/commissions/overview/:year/:month/:producer',
        },
      },
    ],
  },
]

export default routes
