import axios from "axios";
import globals from "@/config/globals";

export default {
  loadSetup() {
    return axios.get(`${globals.getApiHost()}/rater`);
  },

  saveSetup(data) {
    return axios.post(`${globals.getApiHost()}/rater`, data);
  },

  loadApplications() {
    return axios.get(`${globals.getApiHost()}/rater/applications`);
  },

  createApplication(params) {
    return axios.post(`${globals.getApiHost()}/rater/applications`, params);
  }
}
