<template>
  <v-snackbar right @input="snackbarClose" v-model="show" :color="type">
    {{ message }}
  </v-snackbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    name: 'Toast',
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            toast: 'getToastState',
            message: 'getToastMessage',
            type: 'getToastType'
        }),
        show: {
            get() {
                return this.toast
            },
            set() {
                return false
            }
        }
    },
    methods: {
        ...mapActions(['setToast']),
        snackbarClose(val) {
            this.setToast({
                show: val
            })
        }
    }
}
</script>

<style>

</style>