<template>
  <v-btn color="secondary" small depressed id="feedbackModalTab"
         @click="openFeedback" v-show="pendoInitialized">
    Beta Feedback
  </v-btn>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  methods: {
    openFeedback(event) {
      pendo.feedback.openFeedback(event);
    }
  },
  computed:{
    ...mapGetters({
      pendoInitialized: "getPendoTrackingInitialized"
    })
  }
}
</script>

<style scoped>
#feedbackModalTab {
  position: fixed !important;
  right: 0;
  top: 50%;
  margin-right: -50px;


  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
</style>
