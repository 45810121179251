import axios from "axios";
import globals from "@/config/globals";

export default {
  index() {
    return axios.get(`${globals.getApiHost()}/users`);
  },
  show({user}) {
    return axios.get(`${globals.getApiHost()}/users/${user}`);
  }
}
