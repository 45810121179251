import Vue from 'vue'
import Vuex from 'vuex'
import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import authStore from './authStore'
import userStore from './userStore'
import policyStore from './policyStore'
import commissionStore from './commissionStore'
import appStore from './appStore'
import toast from './toast'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth: authStore,
    users: userStore,
    policies: policyStore,
    commissions: commissionStore,
    appConfig: appConfigStoreModule,
    app: appStore,
    toast: toast
  }
})
