import api from '@/api'
import JwtService from '@/services/JwtService'
import axios from 'axios'
import Vue from 'vue'
import * as Sentry from "@sentry/browser";

export default {
  state: {
    loggedIn: false,
    currentUser: {},
    hubSpotTrackingInitialized: false,
    pendoTrackingInitialized: false,
  },
  getters: {
    isUserLoggedIn(state) {
      return state.loggedIn
    },
    getCurrentUser(state) {
      return state.currentUser
    },
    getPendoTrackingInitialized(state){
      return state.pendoTrackingInitialized
    }
  },
  mutations: {
    setUserToLoggedOut(state) {
      JwtService.destroyToken()
      window.location = '/login?loggedOut=true';
    },
    setCurrentUser(state, user) {
      state.currentUser = user;
      state.loggedIn = true;

      Sentry.setUser({email: user.email, id: user.id});
    },
  },
  actions: {
    initializePendo({state}){
      if(process.env.VUE_APP_ENVIRONMENT !== 'production'){
        return false;
      }


      try {
        if (state.pendoTrackingInitialized === false) {
          let pendoUser = {
            visitor: {
              id: state.currentUser.attributes.legacy_id,
              email: state.currentUser.attributes.email,
              full_name: state.currentUser.attributes.first_name + ' ' + state.currentUser.attributes.last_name,
            },

            account: {
              id: state.currentUser.related.agency.data.attributes.legacy_id,
              name: state.currentUser.related.agency.data.attributes.name,
            }
          }

          Vue.prototype.$debug.auth('Initializing Pendo for user', pendoUser);
          pendo.initialize(pendoUser)

          setTimeout(function(){
            state.pendoTrackingInitialized = true;
          },1500);

          Vue.prototype.$debug.auth('Finished initializing Pendo for user');
        }
      }catch (error) {
        Vue.prototype.$debug.error('Error initializing Pendo', error);
      }
    },
    initializeHubSpot({state}) {
      try {
        if (false && process.env.VUE_APP_ENVIRONMENT === 'production' && state.hubSpotTrackingInitialized === false) {
          Vue.prototype.$debug.auth('Initializing HubSpot for user');

          let script = document.createElement('script');
          script.type = 'text/javascript';
          script.id = 'hs-script-loader';
          script.src = '//js-na1.hs-scripts.com/19908677.js';

          //document.head.appendChild(script);

          let _hsq = window._hsq = window._hsq || [];

          _hsq.push(["identify", {
            id: state.currentUser.id,
            first_name: state.currentUser.attributes.first_name,
            last_name: state.currentUser.attributes.last_name,
            email: state.currentUser.attributes.email,
          }]);

          state.hubSpotTrackingInitialized = true;
          Vue.prototype.$debug.auth('Finished initializing HubSpot for user');
        }
      }catch (error) {
        Vue.prototype.$debug.error('Error initializing HubSpot', error);
      }
    },
    authenticate({commit, dispatch}, {email, password}) {
      return new Promise((resolve, reject) => {
        api.auth.login({
          email,
          password,
        }).then(({data}) => {
          JwtService.saveToken(data.attributes.accessToken)
          commit('setCurrentUser', data)

          axios.defaults.headers.common.Authorization = `Bearer ${JwtService.getToken()}`

          dispatch('initializePendo')
          dispatch('initializeHubSpot')

          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    logOut({commit}) {
      return new Promise(resolve => {
        commit('setUserToLoggedOut')

        resolve()
      });
    },
    loadAccount({commit}) {
      return api.auth.account().then(({data}) => {
        console.log('account:refreshed');

        commit('setCurrentUser', data);
      });
    },

    loadUserFromToken({commit, dispatch, state}) {
      Vue.prototype.$debug.auth('Load user from token fired');

      let token = '';

      return new Promise(resolve => {
        if (this._vm.$isEmbedded) {
          Vue.prototype.$debug.auth('Component is embedded. Grabbing token from url.');
          let urlParams = new URLSearchParams(location.search)
          token = urlParams.get('embedToken');
          Vue.prototype.$debug.auth('Url Token: ' + token);

          if (token === null) {
            Vue.prototype.$debug.auth('URL didnt have token, falling back');
            token = JwtService.getToken()
          }
        } else {
          Vue.prototype.$debug.auth('Component is standard. Grabbing token from JWT service.');
          token = JwtService.getToken()
        }

        if (token.length === 0) {
          Vue.prototype.$debug.auth('Token was empty.');
          resolve()

          return
        }

        Vue.prototype.$debug.auth('Token Found: ', token);
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;

        api.auth.account().then(({data}) => {
          Vue.prototype.$debug.auth('Setting current user: ', data);
          commit('setCurrentUser', data)

          dispatch('initializePendo')
          dispatch('initializeHubSpot')

          resolve()
        }).catch(() => {
          resolve()
        })
      })
    }
  }
}
