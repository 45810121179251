export default {
  state: {
    show: false,
    message: '',
    type: 'success'
  },
  getters: {
    getToastState(state) {
      return state.show
    },
    getToastMessage(state) {
        return state.message
    },
    getToastType(state) {
        return state.type
    }
  },
  mutations: {
    setToast(state, payload) {
        state.show = payload.show
        state.message = payload.message
        state.type = payload.type
    }
  },
  actions: {
    setToast({commit}, value) {
        commit('setToast', value)
    }
  }
}
