import api from '@/api'
import utilities from '@/config/globals/utilities'
import _ from 'lodash'
import moment from 'moment'
import Vue from 'vue'

import commissionMonthlyReviewStore from './commissions/commissionMonthlyReviewStore'
import commissionProducersStore from './commissions/commissionProducersStore'
import commissionProducerStatementStore from './commissions/commissionProducerStatementStore'
import commissionUnmatchedStore from './commissions/commissionUnmatchedStore'

export default {
  namespaced: true,
  modules: {
    producers: commissionProducersStore,
    producerStatement: commissionProducerStatementStore,
    unmatched: commissionUnmatchedStore,
    monthlyReview: commissionMonthlyReviewStore,
  },
  state: {
    setup: {},
    setupLoaded: false,
    commissionStatement: {
      user: {},
    },
    commissionsReport: [],
    // producers: [],
    plans: [],
    commissionTable: [],
    userFilterList: [],
  },
  getters: {
    getCommissionSetupLoaded: state => {
      return state.setupLoaded
    },
    getCommissionSetup: state => {
      return state.setup
    },
    getCommissionStatement: state => {
      return state.commissionStatement
    },
    getCommissionStatementTableFormat: state => {
      return state.commissionTable
    },
    getProducers: state => {
      //  return state.producers
    },
    getUserFilterList: state => {
      return state.userFilterList
    },
    getCommissionsReport: state => {
      return state.commissionsReport
    },
  },
  mutations: {
    setSetupLoaded(state) {
      state.setupLoaded = true
    },
    resetCommissions(state) {
      state.setupLoaded = false
    },
    refreshUserFilterList(state) {
      let approved = []
      let needsReview = []
      let finalized = []
      return
      _.forEach(state.producers, user => {
        let structured = {
          value: user.id,
          display: user.first_name + ' ' + user.last_name,
          approved: user.approved,
          finalized: user.finalized,
          system: false,
        }

        if (user.finalized) {
          finalized.push(structured)
        } else {
          user.approved ? approved.push(structured) : needsReview.push(structured)
        }
      })

      let output = [
        {
          display: 'All Producers',
          value: 'all',
          system: true,
        },
        {
          display: 'Unmatched',
          value: 'unmatched',
          system: true,
        },
      ]

      if (needsReview.length) {
        output = _.concat(
          output,
          [
            {
              header: 'Needs Review',
            },
          ],
          needsReview,
        )
      }

      if (approved.length) {
        output = _.concat(
          output,
          [
            {
              header: 'Approved',
            },
          ],
          approved,
        )
      }

      if (finalized.length) {
        output = _.concat(
          output,
          [
            {
              header: 'Finalized',
            },
          ],
          finalized,
        )
      }

      state.userFilterList = output
    },
    refreshTableData(state) {
      state.commissionTable = _.map(state.commissionStatement.commissions, commission => {
        return {
          id: commission.id,
          effective_date: moment(commission.attributes.effective_date).format('MMM Do'),
          type: _.startCase(commission.attributes.reason.code),
          policy: commission.attributes.policy_number,
          carrier: _.startCase(commission.attributes.carrier),
          lob: _.startCase(commission.attributes.policy_type),
          terms: commission.attributes.terms,
          sold_date: (() => {
            let won_date = commission.related?.policy?.data?.attributes?.won_date

            if (won_date && won_date.length) {
              return moment(won_date).format('MMM Do')
            }

            return null
          })(),
          agency_commissions: utilities.formatCurrency(commission.attributes.final_amount),
          agency_commissions_raw: utilities.formatRawValue(commission.attributes.final_amount),
          agent_pay_rate:
            ((commission.attributes.user_split_amount * 100) / commission.attributes.final_amount).toFixed(2) + '%',
          agent_pay_rate_raw: (() => {
            let value = 0
            if (utilities.formatRawValue(commission.attributes.user_split_amount) === 0) {
              value = parseFloat(0).toFixed(2)
            } else {
              value = ((commission.attributes.user_split_amount * 100) / commission.attributes.final_amount).toFixed(2)
            }

            if (isNaN(value)) {
              value = parseFloat(0).toFixed(2)
            }

            return value
          })(),
          agent_pay: utilities.formatCurrency(commission.attributes.user_split_amount),
          agent_pay_raw: utilities.formatRawValue(commission.attributes.user_split_amount),
          premium: utilities.formatCurrency(commission.attributes.premium),
          isNegative: commission.attributes.premium < 0,
          plan: commission.related.plan.data,
          plans: state.commissionStatement.plans,
          insured: commission.attributes.insured_name,
          user_split_overwritten: commission.attributes.user_split_overwritten,
          user_id: state.commissionStatement.user.id,
          errors: [],
          is_manual: commission.attributes.manually_added,
          ivans_status: commission.attributes.ivans_status,
          original: (() => {
            if (commission.related.original_plan === null) {
              return false
            }

            let code = commission.attributes.reason.code
            let percent = false

            if (code === 'NBS') {
              percent = commission.related.original_plan?.data?.attributes?.new_percentage
            } else if (code === 'RWL') {
              percent = commission.related.original_plan?.data?.attributes?.renewal_percentage
            }

            if (!percent) {
              return false
            }

            return {
              percent: percent,
              value: (commission.attributes.final_amount * (percent / 100)).toFixed(2),
            }
          })(),
        }
      })
    },
    setCommissionsReport(state, data) {
      state.commissionsReport = data
    },
    updateSetup(state, data) {
      state.setup = data
    },
    clearSetup(state) {
      state.setup = {}
      state.setupLoaded = false
    },
    updateProducers(state, data) {
      // state.producers = data
      this.commit('refreshUserFilterList')
    },
    setCommissionStatement(state, data) {
      state.commissionStatement = data
      this.commit('refreshTableData')
    },
    updateCommission(state, commission) {
      _.forEach(state.commissionStatement.commissions, (searchCommission, index) => {
        if (commission.id === searchCommission.id) {
          Vue.set(state.commissionStatement.commissions, index, _.cloneDeep(commission))
          return false
        }
      })

      this.commit('refreshTableData')
    },
    setCommissionStatementBonusTotal(state, commissionStatement) {
      state.commissionStatement.bonuses_total = commissionStatement.bonuses_total
    },
    setCommissionStatementBonuses(state, bonuses) {
      state.commissionStatement.bonuses = bonuses
    },
    setCommissionStatementTotal(state, commissionStatement) {
      state.commissionStatement.total = commissionStatement.total
      state.commissionStatement.commissions_total = commissionStatement.commissions_total
    },
    setCommissionStatementApprovalState(state, commissionStatement) {
      state.commissionStatement.user.approved = commissionStatement.user.approved
    },
    clearCommissionStatement(state) {
      state.commissionStatement = {}
    },
  },
  actions: {
    loadCommissionSetup({ commit }) {
      return new Promise((resolve, reject) => {
        api.commissionSetup
          .index()
          .then(({ data }) => {
            commit('updateSetup', data)
            commit('setSetupLoaded')
            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    refreshCommissionStatementTotals({ state, commit }) {
      return api.commissionStatements
        .user({
          user: state.commissionStatement.user.id,
          date: state.commissionStatement.date,
        })
        .then(({ data }) => {
          commit('setCommissionStatementTotal', data)
        })
    },
    refreshCommissionStatement({ state, commit }) {
      return api.commissionStatements
        .user({
          user: state.commissionStatement.user.id,
          date: state.commissionStatement.date,
        })
        .then(({ data }) => {
          commit('setCommissionStatement', data)
        })
    },
    refreshProducerList({ state, commit }) {
      return api.commissionStatements
        .users({
          date: state.commissionStatement.date,
        })
        .then(({ data }) => {
          commit('updateProducers', data)
        })
    },
    refreshCommissionStatementStatus({ state, commit }) {
      return api.commissionStatements
        .user({
          user: state.commissionStatement.user.id,
          date: state.commissionStatement.date,
        })
        .then(({ data }) => {
          commit('setCommissionStatementApprovalState', data)
        })
    },
  },
}
