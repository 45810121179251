// import axios from 'axios';
// import globals from '../config/globals'

import axios from "axios";
import globals from "@/config/globals";

export default {
  index(filters = {}) {

    return axios.get(`${globals.getApiHost()}/policy-types`, {
      params: {
        filters: filters
      }
    });
  },
}
