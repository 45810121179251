import axios from "axios";
import globals from "@/config/globals";

export default {
  index() {
    return axios.get(`${globals.getApiHost()}/commissions-setup`);
  },
  saveSingle({plan}) {
    return axios.post(`${globals.getApiHost()}/commissions-setup`, plan);
  },
  saveGroup({plans}) {
    return axios.post(`${globals.getApiHost()}/commissions-setup`, {
      plans: plans
    });
  },
  saveSelf() {
    return axios.post(`${globals.getApiHost()}/commissions-setup`, {});
  },
  saveAggregator({
                   has_aggregator,
                   aggregator_carriers,
                   aggregator_rate_type,
                   aggregator_percentage,
                   aggregator_flat_rate
                 }) {
    return axios.put(`${globals.getApiHost()}/commissions-setup/aggregator`, {
      has_aggregator,
      aggregator_carriers,
      aggregator_rate_type,
      aggregator_percentage,
      aggregator_flat_rate
    });
  },
  aggregatorCarrierSearch({search}) {
    return axios.get(`${globals.getApiHost()}/commissions-setup/aggregator-carrier-search?search=${search}`);
  },
  savePlanType({type}) {
    return axios.put(`${globals.getApiHost()}/commissions-setup/planType`, {
      type
    });
  },
  savePlans({plans, type}) {
    return axios.put(`${globals.getApiHost()}/commissions-setup/plans`, {
      type,
      plans
    });
  },
  completeSetup() {
    return axios.put(`${globals.getApiHost()}/commissions-setup/complete-setup`, {});
  }
}
