import api from '@/api'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    producers: [],
    activeDate: null,
  },
  getters: {
    getProducers: state => {
      return state.producers
    },
    getActiveDate: state => {
      return state.activeDate
    },
  },
  mutations: {
    setProducers(state, producers) {
      state.producers = producers
    },
    setProducer(state, producer) {
      let index = _.findIndex(state.producers, { id: producer.id })

      state.producers.splice(index, 1, producer)
    },
    setActiveDate(state, date) {
      state.activeDate = date
    },
  },
  actions: {
    loadProducers({ commit, dispatch, state }, { date }) {
      commit('setActiveDate', date)

      return api.commissionStatements
        .users({
          date: date,
        })
        .then(({ data }) => {
          commit('setProducers', data)
        })
    },
    async refreshProducers({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        return api.commissionStatements
          .users({
            date: state.activeDate,
          })
          .then(({ data }) => {
            commit('setProducers', data)
            resolve()
          })
      })
    },
    setProducers({ commit }, producers) {
      commit('setProducers', producers)
    },
    setProducer({ commit }, producer) {
      commit('setProducer', producer)
    },
  },
}
