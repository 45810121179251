import axios from 'axios'
import globals from '@/config/globals'

export default {
  index() {
    return axios.get(`${globals.getApiHost()}/commissions-aggregator/carriers`)
  },
  update({carriers}) {
    return axios.put(`${globals.getApiHost()}/commissions-aggregator/carriers`,{
      carriers
    })
  },
  featured(){
    return axios.get(`${globals.getApiHost()}/commissions-aggregator/featured-carriers`)
  }
}
