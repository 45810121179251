import axios from 'axios'
import globals from '@/config/globals'
import _ from 'lodash'
import qs from 'qs'

export default {
  index({ page = 0, page_size = 30, filters = {} }) {
    let query = {
      page_size: page_size,
      page: page,
      filters: filters,
    }

    return axios.get(`${globals.getApiHost()}/commissions?` + qs.stringify(query))
  },
  unmatched({ page = 0, page_size = 30, filters = {} }) {
    let query = {
      page_size: page_size,
      page: page,
      filters: filters,
    }

    return axios.get(`${globals.getApiHost()}/unmatched-commissions?` + qs.stringify(query))
  },
  assign({ commission, user }) {
    return axios.put(`${globals.getApiHost()}/unmatched-commissions/${commission}`, {
      user_id: user,
    })
  },
  export({ filters = {} }) {
    let query = {
      filters: filters,
    }

    return axios.get(`${globals.getApiHost()}/commissions/export?` + qs.stringify(query))
  },
  updatePlan({ commission, plan }) {
    return axios.put(`${globals.getApiHost()}/commissions/${commission}`, {
      plan,
    })
  },
  updateReason({ commission, reason }) {
    return axios.put(`${globals.getApiHost()}/commissions/${commission}`, {
      reason,
    })
  },
  updateUser({ commission, user }) {
    return axios.put(`${globals.getApiHost()}/commissions/${commission}`, {
      user,
    })
  },
  updateAmount({ commission, user_split_amount }) {
    return axios.put(`${globals.getApiHost()}/commissions/${commission}`, {
      user_split_amount: user_split_amount,
    })
  },
  updateAgencyAmount({ commission, final_amount }) {
    return axios.put(`${globals.getApiHost()}/commissions/${commission}/agency-pay`, {
      final_amount: final_amount,
    })
  },
  bulkAssign({ assignments }) {
    return axios.put(`${globals.getApiHost()}/unmatched-commissions/bulk-assign`, {
      assignments,
    })
  },
}
