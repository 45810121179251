import axios from 'axios';
import globals from '../config/globals'

export default {
    index(filters = {}) {
        return axios.get(`${globals.getApiHost()}/policies`);
    },
    create(data) {
        return axios.post(`${globals.getApiHost()}/policies`, data);
    },
    delete(data) {
        return axios.delete(`${globals.getApiHost()}/policies`, data);
    },
    cancel(data) {
        return axios.post(`${globals.getApiHost()}/policies/${data.id}/cancel`, data.attrs);
    },
    getPolicyTypes() {
        return axios.get(`${globals.getApiHost()}/policy-types`);
    },
    getCarriers() {
        return axios.get(`${globals.getApiHost()}/carriers`, {
            params: {
              filters: {
                'limit': 1000
              }
            }
        });
    }
}
