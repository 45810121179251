import _ from 'lodash'

export default {
  data() {
    return {
      featureFlags: {},
      loadFeatureFlags: {},
      requiredFeatureFlags: [],
    }
  },
  methods: {
    retrieveFeatureFlags() {
      _.forEach(this.loadFeatureFlags, (featureFlag, key) => {
        this.$debug.info('retrieveFeatureFlags', key);
        this.featureFlags[key] = this.$featureFlags.variation(featureFlag);
      })
    },
    checkRequiredFeatureFlags(){
      _.forEach(this.requiredFeatureFlags, (featureFlag) => {
        this.$debug.info('checkRequiredFeatureFlags', featureFlag);
        if(this.$featureFlags.variation(featureFlag) !== true){
          return this.$router.push({name: '404'});
        }
      })
    }
  },
  mounted() {
    this.retrieveFeatureFlags();
    this.checkRequiredFeatureFlags();
  }
}
