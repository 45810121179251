import Vue from 'vue'

export default {
  event: (message, properties = {}) => {
    try {
      pendo.track(message, properties)

      Vue.prototype.$debug.info('Tracking event', {
        event: message,
        properties: properties,
      })
    } catch (error) {}
  },
}
