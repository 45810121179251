const routes = [
  {
    path: 'settings',
    component: () => import('@/pages/commissions/commission-settings/Index.vue'),
    name: 'commission-settings',
    redirect: { name: 'commission-settings.aggregator' },
    children: [
      {
        path: 'aggregator',
        name: 'commission-settings.aggregator',
        component: () => import('@/pages/commissions/commission-settings/steps/Aggregator.vue'),
        meta: {
          layout: 'content',
          requireCommissionSetup: false,
        },
      },
      {
        path: 'plan-select',
        name: 'commission-settings.plan-select',
        component: () => import('@/pages/commissions/commission-settings/steps/PlanSelect.vue'),
        meta: {
          layout: 'content',
          requireCommissionSetup: false,
        },
      },
      {
        path: 'pay-structure',
        name: 'commission-settings.pay-structure',
        component: () => import('@/pages/commissions/commission-settings/steps/PayStructure.vue'),
        meta: {
          layout: 'content',
          requireCommissionSetup: false,
        },
      },
      {
        path: 'completed',
        name: 'commission-settings.completed',
        component: () => import('@/pages/commissions/commission-settings/steps/Completed.vue'),
        meta: {
          layout: 'content',
          requireCommissionSetup: false,
        },
      },
    ]
  },
];

export default routes;
