import axios from 'axios'
import globals from '../config/globals'

export default {
  login({email, password}) {
    return axios.post(`${globals.getApiHost()}/login`, {
      email,
      password,
    })
  },

  account() {
    return axios.get(`${globals.getApiHost()}/account`)
  }
}
