import axios from "axios";
import globals from "@/config/globals";

export default {
  getMakes() {
    return axios.get(`${globals.getApiHost()}/autos/makes`);
  },

  getMakeModels(makeId) {
    return axios.get(`${globals.getApiHost()}/autos/makes/${makeId}/models`);
  },

  getVinData(vin) {
    return axios.post(`${globals.getApiHost()}/autos/search-vin`, {vin: vin});
  }
}
