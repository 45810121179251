import axios from "axios";
import globals from "@/config/globals";
import _ from 'lodash';
import qs from 'qs'

export default {
  details({date}) {
    return axios.get(`${globals.getApiHost()}/commissions-statements/${date}/details`);
  },
  loadAggregatorPercentage({date}) {
    return axios.get(`${globals.getApiHost()}/commissions-statements/${date}/monthly-aggregator-percentage`);
  },
  updateAggregatorPercentage({date, aggregator_rate_type, aggregator_percentage,aggregator_flat_rate}) {
    return axios.put(`${globals.getApiHost()}/commissions-statements/${date}/monthly-aggregator-percentage`, {
      aggregator_rate_type: aggregator_rate_type,
      aggregator_percentage:aggregator_percentage,
      aggregator_flat_rate: aggregator_flat_rate
    });
  },
  user({user, date}) {
    return axios.get(`${globals.getApiHost()}/commissions-statements/${date}/details/${user}`);
  },
  users({page = 0, date}) {
    let query = {
      page: page,
      page_size: 20
    }

    return axios.get(`${globals.getApiHost()}/commissions-statements/${date}/users?` + qs.stringify(query));
  },
  unmatched({page = 0, date}) {
    let query = {
      page: page
    }

    return axios.get(`${globals.getApiHost()}/commissions-statements/${date}?` + qs.stringify(query));
  },
  export({user, date}){
    return axios.get(`${globals.getApiHost()}/commissions-statements/${date}/details/${user}/export`);
  },
  saveBonuses({user, date, bonuses}) {
    return axios.put(`${globals.getApiHost()}/commissions-statements/${date}/details/${user}`, {
      bonuses: bonuses
    });
  },
  approve({user, date}) {
    return axios.put(`${globals.getApiHost()}/commissions-statements/${date}/details/${user}`, {
      approve: true
    });
  },
  unapprove({user, date}) {
    return axios.put(`${globals.getApiHost()}/commissions-statements/${date}/details/${user}`, {
      clearApproval: true
    });
  },
  finalize({date}) {
    return axios.put(`${globals.getApiHost()}/commissions-statements/${date}/finalize`);
  },
}
